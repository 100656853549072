<template>
    <v-container class="l-primary-font f20 fw600 d-flex flex-column mb-10 mt-3">
        
        <v-progress-linear
            v-if="loading"
            indeterminate
            color="l-primary"
            rounded
            height="8"
            class="my-5"
        ></v-progress-linear>
        <section v-else>
            <v-row no-gutters >
                <v-col cols="12" md="8">
                    <span class="mb-3">
                        Other Course Offerings
                    </span>
                    <v-divider class="l-primary my-3" :width="!$vuetify.breakpoint.mobile ? '50%' : '100%'"/>

                </v-col>
                <v-spacer />
                <v-col cols="12" md="3">
                    <v-text-field :loading="searchLoading" outlined dense class="float-right mb-3" color="l-primary" solo flat placeholder="Search courses" v-model="search" hide-details prepend-inner-icon="mdi-magnify"/>
                </v-col>
            </v-row>
            <div v-if="other_courses.length === 0 && !loading" class="fw600 f14 secondary--text my-3"><i>No Courses Available</i></div>

            <div v-for="(item, key) in keys" :key="key" class="mb-3">
                <div class="f16 mb-3" v-if="filtered[item].length > 0">
                    {{ $dateFormat.MMYY(item) }} courses
                </div>
                <v-slide-group
                    show-arrows
                >
                    <v-slide-item v-for="course in filtered[item]" :key="course.id">
                        <galleryCard :course="course" class="ma-1" />
                    </v-slide-item>
                </v-slide-group>
            </div>

            <!-- <section v-if="other_courses.length>0" class="d-flex my-10" :class="[$vuetify.breakpoint.mobile ? 'flex-column justify-center' : 'flex-wrap']">
                <galleryCard v-for="(course, i) in filtered" :key="i" :course="course" class="ma-1" v-if="!$vuetify.breakpoint.mobile"/>
                <listCard v-for="(course, i) in filtered" :key="i" :course="course" class="ma-3" v-if="$vuetify.breakpoint.mobile"/>
            </section> -->
        </section>
        
    </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import galleryCard from "@/components/landing/cards/gallery-card.vue";
import listCard from "@/components/landing/cards/list-card.vue"

export default {
    data:() => ({
        search: '',
        loading: false,
        searchLoading: false,
    }),
    components: {
        galleryCard,
        listCard
    },
    mounted() {
        this.loading = true
        this.getGroupedOtherCoursesAction({ is_free: false}).finally(() => {
            this.loading = false
        })
    },
    computed: {
        ...mapState({
            other_courses: (state) =>  state.other_courses,
            keys: (state) => Object.keys(state.other_courses)
        }),

        filtered() {
            this.searchLoading = true
            
            let _filtered = {}
            if(this.search.length > 0) {
                for (const key in this.other_courses) {
                    if (Array.isArray(this.other_courses[key])) {
                        _filtered[key] = this.other_courses[key].filter(item => item.title.toLowerCase().includes(this.search.toLowerCase()))
                    }
                }
                this.searchLoading = false
                return _filtered
            } else {
                this.searchLoading = false
                return this.other_courses
            }

            
        }
    },
    methods: {
        ...mapActions(['getTenantCoursesAction', 'getGroupedOtherCoursesAction'])
    }
}
</script>